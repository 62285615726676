<template>
  <el-container style="height: 100%;">
    <div v-if="isMobile && sidebar.opened" class="drawer-bg" @click="handleClickOutside"></div>
    <!-- 左侧边栏-->
    <alt-sidebar/>
    <el-container>
      <!--头部-->
      <el-header class="flex">
        <div class="header-left">
          <div class="header-icon" @click="handleToggleSideBar">
            <font-awesome-icon :icon="isCollapse ? 'indent' : 'outdent'"/>
          </div>
          <breadcrumb></breadcrumb>
        </div>
        <div class="header-right">
          <el-select  v-model="wxAppId" placeholder="请选公众号" filterable  @change="onChangWxapp" >
            <el-option v-for="item in publicAccountList" :key="item.id" :label="'g'+item.appId+'-'+item.wxAppName" :value="item.wxAppId"></el-option>
          </el-select>
          <!-- <el-select v-model="appId" placeholder="请选择游戏" filterable @change="onChangGame">
            <el-option v-for="item in gameOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select> -->
          <div class="header-icon" @click="fedLogOut">
            <font-awesome-icon icon="power-off"/>
          </div>
        </div>
      </el-header>
      <!--主体内容-->
      <el-main>
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import p from '@/common/privilege'
import AltSidebar from './AltSidebar'
import ResizeMixin from './mixin/ResizeMixin'
import Breadcrumb from './Breadcrumb'

export default {
  name: 'Layout',
  components: {
    AltSidebar,
    Breadcrumb
  },
  mixins: [ResizeMixin],
  data () {
    return {
      appId: this.$store.getters.appId,
      wxAppId: this.$store.getters.getWxAppid
    }
  },
  created () {
    console.log(this.$store.getters.getWxAppid, 'this.$store.getters.wxAppid')
    this.$store.dispatch('fetchPublicAccounts')
    this.$store.dispatch('GetGames').then((res) => {
      this.appId = this.$store.getters.appId
      const findWxAppId = this.publicAccountList.find(item => item.appId === this.appId)
      if (findWxAppId) this.wxAppId = findWxAppId.wxAppId
      // this.appId = store.getAppId()
      this.$store.dispatch('ChangeGame', this.appId).then(() => {
        // console.log('created game', this.appId, 'channels', this.$store.getters.channels)
      })
    })
    this.$store.dispatch('updateWxAppid', this.wxAppId).then(() => {
      this.$router.push({ path: '/' })
    })
  },
  computed: {
    device () {
      return this.$store.getters.device
    },
    sidebar () {
      return this.$store.getters.sidebar
    },
    isMobile () {
      return this.$store.getters.isMobile
    },
    isCollapse () {
      return !this.sidebar.opened
    },
    gameOptions () {
      return this.$store.getters.games
    },
    publicAccountList () {
      return [...this.$store.getters.publicAccountList]
    }
  },
  methods: {
    onChangGame () {
      this.$store.dispatch('ChangeGame', this.appId).then(() => {
        this.$router.push({ path: '/' })
      })
    },
    onChangWxapp () {
      this.$store.dispatch('updateWxAppid', this.wxAppId).then(() => {
        this.$router.push({ path: '/' })
      })
    },
    fedLogOut () {
      this.$confirm('确认退出吗？', '提示', { type: 'warning' }).then(() => {
        this.$store.dispatch('FedLogOut').then(() => {
          const redirectUri = encodeURIComponent(window.location.origin + '#/login')
          window.location.href = p.OAuthExitUrl + '?redirect_uri=' + redirectUri
        })
      }, _ => {})
    },
    handleToggleSideBar () {
      this.$store.dispatch('ToggleSideBar')
    },
    handleClickOutside () {
      this.$store.dispatch('CloseSideBar', { withoutAnimation: false })
    }
  }
}
</script>

<style scoped>
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
    /* .fade-leave-active for below version 2.1.8 */ {
    transform: translateX(200px);
    opacity: 0;
  }
  .el-header {
    background-color: #ffffff;
    line-height: 60px;
    padding: 0;
    border-bottom: 3px solid #F0F2F5;
  }
  .el-main {
    margin: 0;
    padding: 0 5px;
    text-align: left;
    overflow-x: hidden;
    background-color: #f8f9fa;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-left {
    display: flex;
    justify-content: flex-start;
    flex: 1;
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
  .header-icon {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .header-icon i {
    color: #555555;
  }
  h3 {
    margin: 5px 0;
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
</style>
