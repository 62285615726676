export default {
  channelCascadeOptions: state => state.app.channelCascadeOptions,
  channelCascadeIds: state => state.app.channelCascadeIds,
  dataPickerValue: state => state.app.dataPickerValue,
  servers: state => state.app.servers,
  games: state => state.app.games,
  channels: state => state.app.channels,
  serverId: state => state.app.serverId,
  publicAccountList: state => state.wechat.publicAccountList,
  wxAppId: state => state.wechatStore.wxAppId
  // routerPrivilege: state => state.app.routerPrivilege
}
