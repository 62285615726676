import p from '@/common/privilege'
import Layout from '@/components/Layout'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
function load (view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  { path: '/login', component: load('Login'), hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: true,
    children: [{ path: '/home', name: '首页', component: load('Home'), icon: 'home' }]
  },
  {
    path: '/wechat',
    component: Layout,
    name: '公众号',
    subMenu: true,
    icon: 'fa-gears',
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_LOG, p.P_ALL] },
    children: [
      { path: '/wechat/wx-menu', name: '微信公众号菜单', meta: { privileges: [p.P_ALL, p.P_MP_MENU] }, component: load('wechat/wx-menu'), icon: 'gears' },
      { path: '/wechat/wx-assets', name: '素材管理', meta: { privileges: [p.P_ALL, p.P_MP_ASSETS] }, component: load('wechat/wx-assets'), icon: 'gears' },
      { path: '/wechat/msg-reply-rule', name: '自动回复规则', meta: { privileges: [p.P_ALL, p.P_MP_REPLY] }, component: load('wechat/msg-reply-rule'), icon: 'gears' }
    ]
  },
  {
    path: '/wechat-code',
    component: Layout,
    name: '微信口令礼包码',
    icon: 'fa-cube',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_LOG, p.P_ALL, p.P_GIFT] },
    children: [
      { path: '/wechat-code/code-list', name: '口令列表', component: load('WechatCode/CodeList'), meta: { privileges: [p.P_ALL] }, icon: 'fa-brands fa-codepen' },
      { path: '/wechat-code/code-user-list', name: '用户兑换码列表', meta: { privileges: [p.P_ALL] }, component: load('WechatCode/CodeUserList'), icon: 'fa-receipt' }
    ]
  },
  {
    path: '/rule-wechat-code',
    component: Layout,
    name: '规则匹配礼包码',
    icon: 'fa-cube',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_ALL, p.P_RULE_GIFT] },
    children: [
      { path: '/rule-wechat-code/code-list', name: '礼包码列表', component: load('RuleWeChatCode/CodeList/index'), meta: { privileges: [p.P_ALL, p.P_RULE_GIFT] } },
      { path: '/rule-wechat-code/code-user-list', name: '用户兑换码列表', meta: { privileges: [p.P_ALL, p.P_RULE_GIFT] }, component: load('RuleWeChatCode/CodeUserList/index') }
    ]
  },
  {
    path: '/empty',
    component: Layout,
    redirect: '/empty',
    hidden: true,
    children: [{ path: '/empty', name: 'Empty', component: load('Empty'), icon: 'home' }]
  },
  { path: '*', component: load('404'), hidden: true }
]

const router = new VueRouter({
  routes
})

export default router
