<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created () {
    console.log(process.env.NODE_ENV)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html, body {
  margin: 0;
  padding: 0;
}

html,body,#app{
  height: 100%;
  background: #ECF0F5; /*白色*/
  /*background: #33384B; !*黑色*!*/
}

.main-header {
  padding: 10px 0;
}

.box {
  border-radius: 5px;
  margin-top: 5px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #E4E7ED;
  padding: 10px;
}

.flex {
  display: flex;
}
.flex-direction-column {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
img.image-sm {
    max-width: 80px;
    max-height: 80px;
}
.el-col .el-select,
.el-col .el-date-editor {
    width: 100%;
}
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
.text-warning {
    color: #e6a23c;
}
</style>
