import store from '@/store/index'
import api from '@/api'

const wechatStore = {
  state: {
    publicAccountList: [],
    wxAppid: localStorage.getItem('wxAppid') || ''
  },
  getters: {
    getWxAppid: (state) => state.wxAppid
  },
  mutations: {
    SET_PUBLIC_ACCOUNTS (state, accounts) {
      state.publicAccountList = accounts
      if (accounts.length > 0) {
        // state.wxAppid = accounts[0].wxAppId
        // localStorage.setItem('wxAppid', accounts[0].wxAppId)
      }
    },
    SET_WX_APPID (state, wxAppid) {
      state.wxAppid = wxAppid
      localStorage.setItem('wxAppid', wxAppid)
    }
  },
  actions: {
    fetchPublicAccounts ({ commit }) {
      api.getWeixinList(store.getters.appId).then(res => {
        commit('SET_PUBLIC_ACCOUNTS', res.data)
      })
    },
    updateWxAppid ({ state, commit }, wxAppid) {
      commit('SET_WX_APPID', wxAppid)
      const items = state.publicAccountList.filter(item => item.wxAppId === wxAppid)
      if (items.length > 0) {
        store.dispatch('ChangeGame', items[0].appId)
      }
    }
  }
}

export default wechatStore
