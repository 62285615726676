import store from '@/store'

const OAuthUrl = 'https://auth.begind.cn/oauth/authorize'
const OAuthExitUrl = 'https://auth.begind.cn/oauth/exit'
const ForbidNonce = 'JM3TSCgOrnLGB7V69qfZ'
const P_LOG = 'wxs-login'
const P_RULE_GIFT = 'wxs-rule-gift'
/** 微信公众号菜单 */
const P_MP_MENU = 'wxs_mp_menu'
/** 素材管理 */
const P_MP_ASSETS = 'wxs_mp_assets'
/** 自动回复规则 */
const P_MP_REPLY = 'wxs_mp_reply'
/** 口令礼包码 */
const P_GIFT = 'wxs_gift'
/** 所有组件权限 */
const P_ALL = 'wxs_component_all'
// const P_KEFU = 'gcm-kefu'
// const P_YY = 'gcm-yunying'
// const P_YX = 'gcm-yingxiao'
// const P_ADMIN = 'gcm-admin'
const checkPrivileges = (values, privileges) => {
  if (!privileges || privileges.length <= 0) {
    return true
  }
  // 如果用户信息中没有附带权限或者权限为空，不可访问
  if (!values || values.length <= 0) {
    return false
  }
  // 如果没有权限资格或权限资格为空，所有用户都可访问
  // 用户权限与权限配置对比，存在可访问
  for (const v of values) {
    if (privileges.includes(v)) {
      return true
    }
  }
  return false
}

const checkPrivilegesByPath = (values, path) => {
  const routerPrivilege = store.getters.routerPrivilege
  const privileges = routerPrivilege.get(path)
  return checkPrivileges(values, privileges)
}

export default {
  P_LOG,
  P_RULE_GIFT,

  P_MP_MENU,
  P_MP_ASSETS,
  P_MP_REPLY,
  P_GIFT,
  P_ALL,
  // P_KEFU,
  // P_YY,
  // P_YX,
  // P_ADMIN,
  OAuthUrl,
  OAuthExitUrl,
  ForbidNonce,
  checkPrivileges,
  checkPrivilegesByPath
}
