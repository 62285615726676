import http from '@/api/http'

export default {
  get (path, params) {
    return http.get(path, { params })
  },
  post (path, data, header) {
    return http.post(path, data, header)
  },
  put (path, data) {
    return http.put(path, data)
  },
  patch (path, data) {
    return http.patch(path, data)
  },
  delete (path) {
    return http.delete(path)
  },
  gameList () {
    return http.get('/api/channel/auth-game')
  },
  channelList (appId) {
    return http.get('/api/channel/auth-platform-channel', { params: { appId: appId } })
  },
  gameProducts (appId) {
    return http.get('/api/c/product', { params: { appId: appId } })
  },
  serverList (appId) {
    return http.get(`/bi/app/${appId}/server`)
  },
  getWeixinList (appId) {
    return http.get('/wxs/admin/wxAccount/list', { params: { appId: appId } })
  }
}
